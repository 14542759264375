import { captureSSRScriptErrors } from '@confluence/ssr-scripts-utils';

const onRestrictionsButtonContainerClick = (evt, document, window) => {
	// Choose either the event's target node or offsetParent. For Restrictions Button, the target node
	// could either be the lock icon, inner space around lock icon, or the border of the bounding box
	const node = evt?.target as HTMLElement;
	const offsetParent = evt?.target?.offsetParent as HTMLElement;

	if (!node && !offsetParent) return;

	if (!window.__SSR_EVENTS_CAPTURE__) {
		window.__SSR_EVENTS_CAPTURE__ = {};
	}

	const elementId = node?.getAttribute('data-id');
	const offsetParentId = offsetParent?.getAttribute('data-id');

	const dataId = elementId || (offsetParentId as string);
	try {
		const restrictionsButton = (
			offsetParentId === dataId ? offsetParent : node
		) as HTMLButtonElement;
		if (restrictionsButton?.disabled) {
			return;
		}
		window.__SSR_EVENTS_CAPTURE__.restrictionsButton = evt;
		// spinner logic
		const spinner = document.querySelector(
			'[data-id="restrictions-loading-spinner"]',
		) as HTMLDivElement;

		if (spinner) {
			spinner.style.display = 'flex';
			spinner.style.fontSize = '14px';
		}

		if (restrictionsButton) {
			restrictionsButton.style.display = 'none';
		}
		return;
	} catch (e) {
		// eslint-disable-next-line no-console
		captureSSRScriptErrors(dataId, e as Error);
	}
	return;
};

export const addSSRClickListeners = (document, window) => {
	// only add event click handlers if the SSR feature flag is enabled
	const restrictionsButton = document?.querySelector("[data-id='restrictions-button']");

	restrictionsButton?.addEventListener(
		'click',
		function (evt) {
			onRestrictionsButtonContainerClick(evt, document, window);
		},
		true,
	);
};

addSSRClickListeners(document, window);
