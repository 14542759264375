import { captureSSRScriptErrors } from '@confluence/ssr-scripts-utils';

const onWatchButtonContainerClick = (evt, document, window) => {
	// Choose either the event's target node or offsetParent. For Watch Button, the target node
	// could either be the eye icon, inner space around eye icon, or the border of the bounding box
	const node = evt?.target;
	const offsetParent = evt?.target?.offsetParent;

	if (!node && !offsetParent) return;

	if (!window.__SSR_EVENTS_CAPTURE__) {
		window.__SSR_EVENTS_CAPTURE__ = {};
	}

	const elementId = node?.getAttribute('data-id');
	const offsetParentId = offsetParent?.getAttribute('data-id');

	const dataId = elementId || offsetParentId;
	try {
		const watchButton = offsetParentId === dataId ? offsetParent : node;
		window.__SSR_EVENTS_CAPTURE__.watchButton = evt;
		// spinner logic
		const spinner = document.querySelector('[data-id="watch-loading-spinner"]');
		if (spinner) {
			spinner.style.display = 'flex';
			spinner.style.fontSize = '14px';
		}
		if (watchButton) {
			watchButton.style.display = 'none';
		}
		return;
	} catch (e) {
		// eslint-disable-next-line no-console
		captureSSRScriptErrors(dataId, e);
	}
	return;
};

export const addSSRClickListeners = (document, window) => {
	// only add event click handlers if the SSR feature flag is enabled
	const watchButton = document?.querySelector("[data-id='page-watch-button']");

	watchButton?.addEventListener(
		'click',
		function (evt) {
			onWatchButtonContainerClick(evt, document, window);
		},
		true,
	);
};

addSSRClickListeners(document, window);
