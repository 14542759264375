import { captureSSRScriptErrors } from '@confluence/ssr-scripts-utils';

const onSpaceStarTextButtonClick = (evt, window, document) => {
	// Select button wrapper by data id
	const button = document?.querySelector("[data-testid='space-star-text-button']");

	if (!window.__SSR_EVENTS_CAPTURE__) {
		window.__SSR_EVENTS_CAPTURE__ = {};
	}

	const elementId = button?.getAttribute('data-id');
	try {
		// Attach event to window
		window.__SSR_EVENTS_CAPTURE__.spaceStarTextButton = evt;

		const spinner = document.querySelector('[data-id="space-star-text-loading-spinner"]');
		if (spinner) {
			// Imitate button styles in spinner
			spinner.style.display = 'flex';
			spinner.style.width = `${button.clientWidth}px`;
			spinner.style.marginLeft = '15px';
			spinner.style.borderRadius = '3px';
		}

		// Hide button
		button.style.display = 'none';
	} catch (e) {
		captureSSRScriptErrors(elementId ?? '', e as Error);
	}
};

export const addSSRClickListeners = (document, window) => {
	const spaceStarTextButton = document?.querySelector("[data-testid='space-star-text-button']");

	spaceStarTextButton?.addEventListener(
		'click',
		function (evt) {
			onSpaceStarTextButtonClick(evt, window, document);
		},
		true,
	);
};

addSSRClickListeners(document, window);
